import { ITabList } from '@components/common';
import { Modal } from '@components/common/modal/modal';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { QuickUserMemberObject } from '@components/todayMeetingDetails/components/addQuickUser';
import { AddQuickUserModal } from '@components/todayMeetingDetails/components/addQuickUser/addQuickUserForm';
import { AllParticipantsObject } from '@components/todayMeetingDetails/components/attendedMembers';
import { getDateByRange } from '@helper/utils';
import { useEndUserToggle } from '@hooks/useEndUserToggle';
import { useDeleteMemberMutation } from '@services/hooks/meetings/useDeleteMemberMutation';
import { useGetMeetingQuery } from '@services/hooks/meetings/useGetMeetingQuery';
import { useGetParticipantsQuery } from '@services/hooks/meetings/useGetParticipants';
import { useFilterStore } from '@store/index';
import { ToastType, useTosterStore } from '@store/toster';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmationModal } from '../confirmationModal/confirmationModal';
import { useComponentLevelAccessCheck } from '../../../authGuard/hooks/useComponentLevelAccessCheck';

export const CompletedMeetings = (): ReactElement => {
	const { state } = useLocation();
	const { data, isFetching, error } = useGetMeetingQuery(state?.id);
	const [showQuickAddUser, setShowQuickAddUser] = useState(false);
	const [selectMemberObject, setSelectedMemberObject] = useState<QuickUserMemberObject | undefined>(undefined);
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
	const navigate = useNavigate();
	const checkConductedMeeting = useComponentLevelAccessCheck('meetings-conducted');
	console.log(checkConductedMeeting, 'Conductes');

	const AttendedTabList: ITabList[] = [
		{
			key: 1,
			name: 'All',
			pathUrl: '/meetings',
			tabIcon: 'icon-user',
		},
		{
			key: 2,
			name: 'Attended Member',
			pathUrl: '/meetings/todays-meetings',
			tabIcon: 'icon-user',
		},
		{
			key: 3,
			name: 'Kyc Pending',
			pathUrl: '/meetings/all-meetings',
			tabIcon: 'icon-user',
		},
		{
			key: 4,
			name: 'Non-End User',
			pathUrl: '/meetings/todays-meetings',
			tabIcon: 'icon-user',
		},
	];

	const { toggleTab } = useEndUserToggle();
	const { updateSearch } = useFilterStore();
	const [activeTab, setActiveTab] = useState(0);
	const { setToaster } = useTosterStore();

	useEffect(() => {
		toggleTab();
		window.addEventListener('resize', toggleTab);
	}, [toggleTab, activeTab]);

	const handleResetSearch = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number): void => {
		e.preventDefault();
		setActiveTab(index);
		updateSearch('');
	};
	const { mutate } = useDeleteMemberMutation(state?.id);
	const {
		data: participants,
		refetch: refetchGetParticipants,
		isFetching: participantsFetching,
		error: participantsError,
	} = useGetParticipantsQuery({ id: state?.id });

	const allLeadUsers =
		(participants?.length !== 0 &&
			participants?.filter((item) => item.pidilite_employ_c === true && item.attendance_c === true)) ||
		[];

	const attendedParticipants =
		(participants?.length !== 0 &&
			participants?.filter(
				(item) => item.attendance_c === true && (item.type === 'Contact' || item.type === 'Lead')
			)) ||
		[];

	const kycPendingParticipants = participants?.filter(
		(item) =>
			item.pidilite_employ_c === false &&
			item.non_invited_c === true &&
			item.attendance_c === true &&
			item.type === 'Lead'
	);
	const nonEndUser = participants?.filter((item) => item.pidilite_employ_c === true && item.attendance_c === true);

	const getParticipants = (id: number): AllParticipantsObject[] => {
		const mergeUnique = (arr1: AllParticipantsObject[], arr2: AllParticipantsObject[]): AllParticipantsObject[] => {
			const combined = [...arr1, ...arr2];
			const unique = new Map(combined.map((item) => [item.id, item]));
			return Array.from(unique.values());
		};
		switch (id) {
			case 0:
				return mergeUnique(attendedParticipants, allLeadUsers) ?? [];
			case 1:
				return attendedParticipants ?? [];
			case 2:
				return kycPendingParticipants ?? [];
			case 3:
				return nonEndUser ?? [];
			default:
				return mergeUnique(attendedParticipants, allLeadUsers) ?? [];
		}
	};

	const handleDeleteMember = useCallback(
		(id: number): void => {
			mutate(`${id}`, {
				onSuccess: () => {
					refetchGetParticipants?.();
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error.message);
				},
			});
		},
		[mutate, refetchGetParticipants, setToaster]
	);

	const handleRenameClick = (memberData: AllParticipantsObject): void => {
		setSelectedMemberObject({
			firstName: memberData.firstname,
			lastName: memberData.lastname,
			primary_number: memberData.phone,
			secondary_number: memberData.secondarymobileno_c,
			productHierarchyCode: memberData.producthierarchycode_c,
			leadId: `${memberData.lead_id}`,
		});
		setShowQuickAddUser(true);
	};

	const handleCompletedAttendance = useCallback((): void => {
		setConfirmationModal(false);
		setShouldNavigate(true);
	}, []);

	useEffect(() => {
		if (shouldNavigate) {
			navigate('/meetings-conducted/expense-details', {
				state: { id: state?.id, membersCount: attendedParticipants?.length },
			});
		}
	}, [shouldNavigate, navigate, state?.id, attendedParticipants?.length]);

	const shouldShowRenameButton = (item: AllParticipantsObject): boolean => {
		const isNonEndUser = nonEndUser?.some((user) => user.id === item.id);
		const isLead = item.type === 'Lead';

		if (activeTab === 0 || activeTab === 1) {
			return isNonEndUser || isLead;
		} else {
			return !isNonEndUser && isLead;
		}
	};

	return (
		<Wrapper isLoaderVisible={isFetching} isError={error}>
			<>
				<section className="meeting-conducted-section">
					<div className="container">
						<div className="breadcrumb-wrapper">
							<ul className="breadcrumb-list">
								<li>
									<a
										aria-label="MeetingConducted"
										className="active"
										href="/meetings-conducted"
										onClick={(e) => e.preventDefault()}
									>
										Complete Attendance
									</a>
								</li>
							</ul>
						</div>

						<div className="meeting-conducted-details">
							<div className="left-details">
								<span className="h3">{data?.name}</span>
								<p className="number">{data?.club_name_c}</p>
							</div>

							<span className="date">{data?.startdate}</span>
						</div>

						{checkConductedMeeting && (
							<div className="qr-box">
								<span className="h4">Do you want to add non-end user?</span>
								<a
									href="/"
									aria-label="qr scan"
									className="qr-scan"
									onClick={(e) => {
										e.preventDefault();
										setShowQuickAddUser(true);
									}}
								>
									<img src="" alt="barcode" className="hide" title="barcode" width="16" height="16" />
									<span className="icon-add-user"></span>
								</a>
							</div>
						)}

						<div className="tab-search-wrapper">
							<div className="tab-wrapper">
								<div className="tab-main">
									<div className="tab-inner">
										<ul className="tab">
											{AttendedTabList.map((tab: ITabList, index: number) => {
												return (
													<li key={tab.name + index}>
														<a
															href={tab.pathUrl}
															aria-label={tab.name}
															className={`tablinks ${activeTab === index && 'active'} `}
															onClick={(e) => handleResetSearch(e, index)}
														>
															<span className="icon">
																<span className={tab.tabIcon}></span>
															</span>
															<span className="tab-name">{tab.name}</span>
															<span className="tab-count">[{getParticipants(index).length}]</span>
														</a>
													</li>
												);
											})}
										</ul>
										<div className="sliding-indicator"></div>
									</div>
								</div>
							</div>
						</div>

						<Wrapper isLoaderVisible={participantsFetching} isError={participantsError}>
							<ul className="meeting-conducted-card-list row">
								{getParticipants(activeTab).length > 0 ? (
									getParticipants(activeTab).map((item) => {
										return (
											<li className="d-col d-col-2" key={item.id}>
												<div className="meeting-conducted-card-main">
													<div className="meeting-conducted-card">
														<div className="left-content">
															<p className="about-name">
																<span className="h3">
																	{item.firstname} {item.lastname}
																</span>
																{kycPendingParticipants?.find((data) => data.id === item.id) ||
																nonEndUser?.find((nonEndUserItem) => nonEndUserItem.id === item.id) ? (
																	<span className="user-icon not-part">
																		<span className="icon-non-kyc-icon"></span>
																	</span>
																) : (
																	<span className="user-icon">
																		<span className="icon-user"></span>
																	</span>
																)}
															</p>
															<div className="club-details">
																<span className="club-name">{item.clubname ?? '--'}</span>
																<span className="club-number">{item.phone}</span>
															</div>
														</div>

														{checkConductedMeeting && (
															<button
																type="button"
																aria-label="trash"
																className="remove-btn"
																onClick={() => handleDeleteMember(item?.member_id)}
															>
																<span className="icon-trash"></span>
															</button>
														)}
													</div>

													{shouldShowRenameButton(item) ||
														(checkConductedMeeting && (
															<div className="rename-btn-wrapper">
																<button
																	type="button"
																	aria-label="rename"
																	className="rename-link-btn rename-btn"
																	onClick={() => handleRenameClick(item)}
																	disabled={data?.status !== 'List Of Meeting Conducted'}
																>
																	<span className="icon-edit-2 icon"></span>
																	<span className="content">Rename</span>
																</button>
															</div>
														))}
												</div>
											</li>
										);
									})
								) : (
									<NoDataFound />
								)}
							</ul>
						</Wrapper>

						{checkConductedMeeting && (
							<div className="submit-btn">
								<p className="note">
									Note: Please add meeting participants before{' '}
									{data?.enddate ? getDateByRange(new Date(data?.enddate), 5) : new Date().toLocaleDateString()}
								</p>
								<button
									className="btn btn-medium btn-primary"
									onClick={() => {
										setConfirmationModal(true);
									}}
								>
									Complete Attendance
								</button>
							</div>
						)}
					</div>
				</section>
				<Modal
					modalId="quickAddEndUser"
					className="dialog dialog-lg  dialog-bottom qr-scan-wrapper quick-add-user-pop"
					modalOpen={showQuickAddUser}
					onModalClose={() => {
						setShowQuickAddUser(false);
						setSelectedMemberObject(undefined);
					}}
				>
					<AddQuickUserModal
						dateObject={{ id: state?.id }}
						onClose={() => {
							setShowQuickAddUser(false);
							setSelectedMemberObject(undefined);
						}}
						refetch={() => refetchGetParticipants?.()}
						details={selectMemberObject}
						isForQuickUser={selectMemberObject?.leadId ? false : true}
						participants={attendedParticipants}
					/>
				</Modal>
				<Modal
					modalId="message"
					className="dialog dialog-sm dialog-lg"
					modalOpen={confirmationModal}
					onModalClose={() => {
						setConfirmationModal(false);
					}}
				>
					<ConfirmationModal
						onClose={() => {
							setConfirmationModal(false);
						}}
						onSubmit={handleCompletedAttendance}
						message="Deduction will happen for all pending KYCs not completed within 5 days, after this stage. Complete all pending KYCs and then submit manual meeting complete button"
						question="Are you sure to complete attendance?"
					/>
				</Modal>
			</>
		</Wrapper>
	);
};
