export * from './contractorDetailForm';

export interface IContractorDetailFormProps {
	setIsContractorModalOpen: (val: boolean) => void;
	dealerDetails?: IDealerDetails | null;
	action: string;
	imageUploadCallBack?: (payload: TSIContractorImageFormFields) => void;
}

export interface IDealerDetails {
	primaryDealer: string | null;
	wssTerritory: string;
	tsiTerritory: string;
	town: string;
	action: string;
}

export interface IContractorDetailForm {
	firstName: string;
	lastName: string;
	mobileNumber: string;
	primaryDealer: string | null;
	wssTerritory: string;
	tsiTerritory: string;
	town: string;
	productImages: File[];
	action: string;
}

export const contractorDetailFormFormInitialValues: IContractorDetailForm = {
	firstName: '',
	lastName: '',
	mobileNumber: '',
	primaryDealer: '',
	wssTerritory: '',
	tsiTerritory: '',
	town: '',
	productImages: [],
	action: '',
};

export interface TSIContractorImageFormFields {
	documenturl?: string;
	primarydealercode?: string;
	primarydealername?: string;
	source?: string;
	referral_source_c?: string;
}
