import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { TSIContractorImageFormFields } from '@components/delaerVisit/components/contractorDetailForm';
import { ErrorHandler } from '@helper/errorHandler';

interface ITSIUploadImageData {
	mutate: UseMutateFunction<TSIContractorImageFormFields, Error, TSIContractorImageFormFields, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useTSIUploadImage = (): ITSIUploadImageData => {
	const uploadImageDetails = async (
		dealerDetails: TSIContractorImageFormFields
	): Promise<TSIContractorImageFormFields> => {
		try {
			return await APIService.postData<TSIContractorImageFormFields>(`${APICONFIG.TSI_IMAGE_UPLOAD}`, dealerDetails);
		} catch (error) {
			ErrorHandler(error);
			return {};
		}
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: uploadImageDetails,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
