import '@assets/styles/pages/dealer-visit.scss';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, RadioButton, SubmitButton } from '@components/common';
import { useFormik } from 'formik';
import {
	activityDiscussionProducts,
	contractorOptions,
	convertUrlToFile,
	dealerVisitFormInitialValues,
	isNullOrEmpty,
	parseMultiselectOptions,
	premiumProducts,
} from '.';
import { useGetRxDb } from '@hooks/getRxdbData';
import { IDealerDetails, IDealerVisitProducts } from '@components/delaerVisit';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { ToastType, useTosterStore } from '@store/toster';
import { maxFileSize } from '@config/constant';
import { useValidation } from '@hooks/validations';
import { useGeoLocationDetails } from '@hooks/useGeoLocationDetails';
import {
	useDealerVisitMutation,
	useDealerVisits,
	useMemberDetails,
	useRetrieveDealerVisitDetails,
	useTSIUploadImage,
} from '@services/hooks/dealerVisit';
import { useLocation, useNavigate } from 'react-router-dom';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { usePresignedURLQuery } from '@services/hooks/enduser-details/usePresignedURLQuery';
import { useUploadSiteImagesMutation } from '@services/hooks/site-leads/useUploadSiteImagesMutation';
import { useDebounce } from '@hooks/useDebounce';
import { Modal } from '@components/common/modal/modal';
import { ContractorDetailForm, TSIContractorImageFormFields } from '../contractorDetailForm';
import { IPrimaryDealerList } from '@components/onetoonemeetings';
import { ProfileData } from '@services/hooks/profile';
import { DelaerVisitBDELevelFields } from '../delaerVisitBDELevelFields';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';
import { useImageUpload } from '@hooks/useImageUpload';
import { ProductImages } from '@components/siteLeads/components/siteProducts/productImages';

export const AddEditDealerVisit = (): ReactElement => {
	const { state, pathname } = useLocation();
	const isEditMode = pathname === '/edit-dealer-visit';
	const navigate = useNavigate();
	const { setToaster } = useTosterStore();
	const { DealerVisitValidationSchema } = useValidation();
	const { refetch: refetchDealerVisits } = useDealerVisits();
	const { result: visitProducts } = useGetRxDb<IDealerVisitProducts>('dealer_visit_products');
	const { result: primaryDealersList } = useGetRxDb<IPrimaryDealerList>('dealers_list');
	const { result: profileData } = useGetRxDb<ProfileData>('profile');
	const { locationDetails } = useGeoLocationDetails();
	const {
		data: delaerDeatils,
		isFetching: isDealerFetching,
		error: dealerError,
	} = useRetrieveDealerVisitDetails(state?.dealerId);
	const { mutate: createAndUpdateDealerVisit, isPending: isDealerVisitPending } = useDealerVisitMutation(
		state?.dealerId
	);
	const { mutate: mutatePresignedURL, data: getSiteImagesPresignedUrl } = usePresignedURLQuery();
	const { mutate: putSiteImagesData, isPending: isPutSiteImagePending } =
		useUploadSiteImagesMutation(getSiteImagesPresignedUrl);
	const { mutate: fetchMemberDetails, isPending: isMemberDetailPending } = useMemberDetails();
	const [isContractorModalOpen, setIsContractorModalOpen] = useState(false);
	const { handleAddImage } = useImageUpload();
	const checkBDEPermissions = useComponentLevelAccessCheck('tsi-dealer-fields');
	const checkTSIPermissions = useComponentLevelAccessCheck('bde-dealer-fields');
	const [imageUploadPayload, setImageUploadPayload] = useState<TSIContractorImageFormFields | null>(null);
	const { mutate: uploadTSIImageDetails } = useTSIUploadImage();

	const formik = useFormik({
		initialValues: dealerVisitFormInitialValues,
		onSubmit: () => {},
		validationSchema: DealerVisitValidationSchema,
	});

	const dealerVisitProducts = useMemo((): DropdownOptions[] => {
		if (visitProducts?.length) {
			return [...visitProducts]
				.sort((a, b) => a.productgroup3description_c.localeCompare(b.productgroup3description_c))
				.map((d, index) => ({
					id: index,
					value: d.productgroup3description_c,
				}));
		}
		return [];
	}, [visitProducts]);

	const dealersList = useMemo((): DropdownOptions[] => {
		if (primaryDealersList?.length) {
			return [...primaryDealersList]
				.sort((a, b) => a.dealername_c.localeCompare(b.dealername_c))
				.map((d) => ({
					id: d?.dealercode_c,
					value: `${d?.dealername_c} - ${d?.dealercode_c}`,
					name: d?.dealername_c,
				}));
		}
		return [];
	}, [primaryDealersList]);

	const handleNumberChange = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length === 10) {
			fetchMemberDetails?.(e.target.value, {
				onSuccess: (data) => {
					const isSuccessful = data?.message.toLowerCase() === 'success';
					if (isSuccessful) {
						const fullName = [data?.data?.firstname, data?.data?.lastname].filter(Boolean).join(' ') || '';
						e.target.dataset?.name && formik.setFieldValue(e.target.dataset?.name, fullName);
						e.target.dataset?.field && formik.setFieldValue(e.target.dataset?.field, true);
					}
				},
			});
		}

		e.target.dataset?.name && formik.setFieldValue(e.target.dataset?.name, '');
		e.target.dataset?.field && formik.setFieldValue(e.target.dataset?.field, false);
	}, 100);

	const loadImages = async (
		dealer_display_image1_c?: string | null,
		dealer_display_image2_c?: string | null
	): Promise<void> => {
		const imageUrls = [];
		if (dealer_display_image1_c) imageUrls.push(dealer_display_image1_c);
		if (dealer_display_image2_c) imageUrls.push(dealer_display_image2_c);

		const promises = imageUrls.map((url) => convertUrlToFile(url));
		const files = await Promise.all(promises);
		if (files.length > 0) {
			if (isEditMode && checkTSIPermissions) {
				formik.setFieldValue('contractorOption', 'Image Upload');
			}
			formik.setFieldValue('productImages', files);
		}
	};

	const handleImageAdd = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const maxImageLimit = 2;
			const folderName = 'dealer_visit';
			const result = handleAddImage(event, formik.values.productImages, maxFileSize, maxImageLimit, folderName);
			if (result) {
				const { payload, updatedImages } = result;
				updatedImages && formik.setFieldValue('productImages', updatedImages);
				payload && mutatePresignedURL?.(payload);
			}
		},
		[formik, handleAddImage, mutatePresignedURL]
	);

	const handleFormSubmit = useCallback((): void => {
		if (checkTSIPermissions && formik?.values?.contractorOption === 'Image Upload') {
			if (!imageUploadPayload || !imageUploadPayload.documenturl) {
				setToaster(true, ToastType.error, `Please add image!`);
				return;
			}
			uploadTSIImageDetails?.(imageUploadPayload, {
				onSuccess: () => {
					handleSubmit();
				},
				onError: () => {
					setToaster(true, ToastType.error, `Error while adding lead!`);
				},
			});
			return;
		}
		// eslint-disable-next-line
	}, [formik.values, imageUploadPayload, setToaster, uploadTSIImageDetails]);

	const handleSubmit = useCallback((): void => {
		const {
			dealerName,
			siteLocation,
			address,
			state: stateLocation,
			city,
			contractorName1,
			contractorName2,
			contractorNumber1,
			contractorNumber2,
			feedback,
			siteAddress,
			premiumProducts,
			productCompetitionFighting,
			activityDiscussed,
			productRSI,
			orderTaken,
			orderTakenVolume,
			productImages,
		} = formik.values;

		let payload: IDealerDetails = {
			dealer_name_c: dealerName,
			location_c: siteLocation,
			address_c: address,
			state_c: isNullOrEmpty(stateLocation),
			city_c: isNullOrEmpty(city),
			premium_product_discussed_c: isNullOrEmpty(premiumProducts),
			product_discussed_for_rsi_c: isNullOrEmpty(productRSI),
			product_discussed_for_competition_fighti_c: isNullOrEmpty(productCompetitionFighting),
			activity_discussion_c: isNullOrEmpty(activityDiscussed),
			order_taken_c: isNullOrEmpty(orderTaken),
			order_taken_volumn_c: isNullOrEmpty(orderTakenVolume),
			contractor_lead_name_1_c: isNullOrEmpty(contractorName1),
			contractor_lead_name_2_c: isNullOrEmpty(contractorName2),
			contractor_lead_number_1_c: isNullOrEmpty(contractorNumber1),
			contractor_lead_number_2_c: isNullOrEmpty(contractorNumber2),
			site_lead_address_c: isNullOrEmpty(siteAddress),
			feedback_c: isNullOrEmpty(feedback),
			dealer_display_image1_c: productImages[0] ? getSiteImagesPresignedUrl?.[0]?.normal_url : null,
			dealer_display_image2_c: productImages[1] ? getSiteImagesPresignedUrl?.[1]?.normal_url : null,
		};

		putSiteImagesData?.(productImages, {
			onSuccess: () => {
				createAndUpdateDealerVisit?.(payload, {
					onSuccess: () => {
						setToaster(
							true,
							ToastType.success,
							`Dealer visit ${state?.dealerId ? 'updated' : 'created'} successfully.`
						);
						refetchDealerVisits?.();
						navigate('/dealer-visit-summary');
					},
					onError: (error) => {
						setToaster(true, ToastType.error, error?.message);
					},
				});
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error?.message);
			},
		});

		if (checkTSIPermissions) {
			payload = { ...payload, dealer_display_image1_c: imageUploadPayload?.documenturl ?? null };
			createAndUpdateDealerVisit?.(payload, {
				onSuccess: () => {
					setToaster(true, ToastType.success, `Dealer visit ${state?.dealerId ? 'updated' : 'created'} successfully.`);
					refetchDealerVisits?.();
					navigate('/dealer-visit-summary');
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error?.message);
				},
			});
			return;
		}
	}, [
		createAndUpdateDealerVisit,
		formik.values,
		getSiteImagesPresignedUrl,
		navigate,
		putSiteImagesData,
		refetchDealerVisits,
		setToaster,
		state?.dealerId,
		imageUploadPayload,
	]);

	useEffect(() => {
		if (profileData[0]?.usertype_c) formik.setFieldValue('role', profileData[0].usertype_c);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileData]);

	useEffect(() => {
		if (!state?.dealerId && locationDetails) {
			const { fullAddress, state, city } = locationDetails;
			const address = fullAddress.split(',').slice(0, -3).join(',').trim();
			formik.setFieldValue('siteLocation', fullAddress, true);
			formik.setFieldValue('address', address, true);
			formik.setFieldValue('state', state, true);
			formik.setFieldValue('city', city, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, locationDetails]);

	useEffect(() => {
		if (state?.dealerId && delaerDeatils && dealerVisitProducts) {
			const {
				dealer_name_c,
				city_c,
				location_c,
				address_c,
				state_c,
				dealer_display_image1_c,
				dealer_display_image2_c,
			} = delaerDeatils;

			loadImages(dealer_display_image1_c, dealer_display_image2_c);

			formik.setValues(
				{
					...formik.values,
					dealerName: dealer_name_c,
					siteLocation: location_c,
					address: address_c,
					city: city_c,
					state: state_c,
					premiumProducts: parseMultiselectOptions(delaerDeatils?.premium_product_discussed_c, premiumProducts),
					productRSI: parseMultiselectOptions(delaerDeatils?.product_discussed_for_rsi_c, dealerVisitProducts),
					productCompetitionFighting: parseMultiselectOptions(
						delaerDeatils?.product_discussed_for_competition_fighti_c,
						dealerVisitProducts
					),
					activityDiscussed: parseMultiselectOptions(delaerDeatils?.activity_discussion_c, activityDiscussionProducts),
					contractorName1: delaerDeatils?.contractor_lead_name_1_c,
					contractorName2: delaerDeatils?.contractor_lead_name_2_c,
					contractorNumber1: delaerDeatils?.contractor_lead_number_1_c,
					contractorNumber2: delaerDeatils?.contractor_lead_number_2_c,
					siteAddress: delaerDeatils?.site_lead_address_c,
					orderTaken: parseMultiselectOptions(delaerDeatils?.order_taken_c, dealerVisitProducts),
					orderTakenVolume:
						delaerDeatils?.order_taken_volumn_c && parseInt(delaerDeatils?.order_taken_volumn_c).toString(),
					feedback: delaerDeatils?.feedback_c,
					isContractorName1Disabled: !!delaerDeatils?.contractor_lead_name_1_c,
					isContractorName2Disabled: !!delaerDeatils?.contractor_lead_name_2_c,
				},
				true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, delaerDeatils, dealerVisitProducts]);

	const dealerDetails = useMemo(() => {
		if (!primaryDealersList?.length) return null;

		const dealerCode = formik.values.dealerName?.split('-')[1]?.trim();
		const dealer = primaryDealersList?.find((d) => d.dealercode_c === dealerCode);

		if (dealer && dealerCode) {
			return {
				primaryDealer: formik.values.dealerName,
				wssTerritory: `${dealer?.hierarchylevel1wssterritory_c} - ${dealer?.descriptionofhierarchylevel1_c}`,
				tsiTerritory: `${dealer?.tsicode_c} - ${dealer?.tsiname_c}`,
				town: `${dealer?.citycode_c} - ${dealer?.towndescription_c}`,
				action: formik.values.contractorOption,
			};
		}
	}, [formik.values.contractorOption, formik.values.dealerName, primaryDealersList]);

	const matchingDealer = useMemo(() => {
		return dealersList?.find((dealer) => dealer?.value === formik.values.dealerName);
	}, [dealersList, formik.values.dealerName]);

	const imageUploadCallBack = (data: TSIContractorImageFormFields): void => {
		loadImages(data.documenturl);
		setImageUploadPayload(data);
	};

	const submitButton = (): ReactElement => {
		if (isEditMode && checkTSIPermissions) return <></>;
		return (
			<SubmitButton
				label="Submit"
				isDisabled={
					!formik.isValid || !formik.dirty || isDealerVisitPending || isPutSiteImagePending || isMemberDetailPending
				}
				isPending={isDealerVisitPending || isPutSiteImagePending}
				handleSubmitClick={checkTSIPermissions ? handleFormSubmit : handleSubmit}
			/>
		);
	};

	return (
		<div className="form-popup show">
			<section className="add-dealer-visit-section new-site-desktop overflow desktop-popup">
				<Wrapper isLoaderVisible={isDealerFetching} isError={dealerError}>
					<div className="container">
						<form>
							<ul className="row">
								<li className="form-control d-col d-col-2">
									<Dropdown
										id="dealerName"
										name="dealerName"
										label="Dealer Name"
										onBlur={formik.handleBlur}
										title={formik.values.dealerName ?? ''}
										error={formik.touched.dealerName && formik.errors.dealerName ? formik.errors.dealerName : null}
										options={dealersList}
										setFieldValue={formik.setFieldValue}
										onChange={() => formik.setFieldValue('contractorOption', '')}
										listFetching={true}
										disabled={isEditMode && checkTSIPermissions}
										required
										blurValidate
									/>
								</li>

								{checkTSIPermissions && (
									<>
										<li className="form-radio d-col d-col-2 dealer-visit-radio">
											<RadioButton
												name="contractorOption"
												title="Please choose to enter Contractor Details"
												checked={matchingDealer ? formik.values.contractorOption : ''}
												radioOptions={contractorOptions}
												onChange={formik.handleChange}
												handleClick={() => setIsContractorModalOpen(true)}
												isDisabled={isEditMode || !matchingDealer}
											/>
										</li>
										{formik?.values?.productImages?.length > 0 && (
											<li className="d-col d-col-2">
												<ProductImages
													fieldName="productImages"
													title="Contractor/Leads Image"
													formik={formik}
													showAddButton={false}
													showRemoveButton={false}
													required={true}
													handleAddImage={handleImageAdd}
												/>
											</li>
										)}
									</>
								)}

								{checkBDEPermissions && (
									<DelaerVisitBDELevelFields
										formik={formik}
										dealerVisitProducts={dealerVisitProducts}
										handleChangeNumber={handleNumberChange}
										handleImageAdd={handleImageAdd}
									/>
								)}
							</ul>
						</form>

						{submitButton()}
					</div>
				</Wrapper>
			</section>
			<Modal
				modalId="contractorDetailForm"
				className="dialog dialog-lg  dialog-bottom qr-scan-wrapper quick-add-user-pop"
				modalOpen={isContractorModalOpen}
				onModalClose={() => {
					setIsContractorModalOpen(false);
				}}
			>
				<ContractorDetailForm
					setIsContractorModalOpen={setIsContractorModalOpen}
					dealerDetails={dealerDetails}
					action={formik.values.contractorOption}
					imageUploadCallBack={imageUploadCallBack}
				/>
			</Modal>
		</div>
	);
};
